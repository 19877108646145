<template>
  <b-pagination
    :total="pages.count"
    :current="pages.current"
    :per-page="pages.per_page || '50'"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    @change="setPage"
    class="mt-3"
  >
  </b-pagination>
</template>
<script>
export default {
  name: 'Page',
  props: ['links', 'pages', 'per_page'],
  data () {
    return {
      rounded: true
    }
  },
  methods: {
    setLink (url) {
      if (url) {
        this.$emit('set-link', url)
      }
    },
    setPage (page) {
      if (page !== this.pages.current) {
        this.$emit('set-page', page)
      }
    }
  }
}
</script>
