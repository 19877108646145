<template>
  <section class="section">
    <div class="content has-text-grey has-text-centered">
      <template v-if="isLoading">
        <p>
          <b-icon icon="dots-horizontal" size="is-large"/>
        </p>
        <p>Cargando Datos...</p>
      </template>
      <template v-else>
        <p>
          <b-icon icon="emoticon-sad" size="is-large"/>
        </p>
        <p>No hay nada que mostrar&hellip;</p>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isLoading: Boolean
  }
}
</script>
