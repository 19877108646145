export default {
  methods: {
    formatDateSimple (date) {
      return date.toLocaleDateString('es-PE') // DD-MM-YYYY
    },
    formatDateReverse (date) {
      const year = date.getFullYear().toString()
      const month = (date.getMonth() + 101).toString().substring(1)
      const day = (date.getDate() + 100).toString().substring(1)
      return year + '-' + month + '-' + day // YYYY-MM-DD
    },
    formatDateInverted (date) {
      var year = date.substr(0, 4)
      var month = date.substr(5, 2) - 1
      var day = date.substr(8, 2)
      var d = new Date(year, month, day)
      var newDate = d.toString()
      return newDate // Date Name-Day Month Day Year 00:00:00 GMT-0500
    }
  }
}
