<template>
  <b-modal v-model="modalLocal" :width="448">
    <div class="card" style="width: auto">
      <header class="modal-card-head">
        <h3 class="modal-card-title is-size-4 has-text-weight-medium">
          Locales
        </h3>
        <button
          type="button"
          class="delete"
          @click="modalLocal = false"
        />
      </header>
      <section class="modal-card-body">
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
        >
          <b-table-column label="Nombre" field="name" v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column v-slot="props">
            <div class="has-text-right">
              <b-checkbox
                :id="props.row.id"
                :native-value="{ id: props.row.id, name: props.row.name }"
                v-model="checkedRows"
              />
            </div>
          </b-table-column>
          <loading-table slot="empty" :isLoading="isLoading" />
        </b-table>
        <div class="m-4 has-text-right">
          <small>
            <b>Total Seleccionados</b>: {{ checkedRows.length }}
          </small>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Guardar"
          type="is-primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="saveData"
        />
        <b-button
          label="Cerrar"
          type="is-dark"
          @click="modalLocal = false"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import ProductService from '@/services/ProductService'
import LoadingTable from '@/components/Base/LoadingTable'
export default {
  name: 'ModalLocal',
  props: {
    prevCheckedRows: {
      type: Array,
      default: () => []
    }
  },
  components: {
    LoadingTable
  },
  data () {
    return {
      modalLocal: true,
      isLoading: false,
      results: [],
      checkedRows: []
    }
  },
  watch: {
    modalLocal () {
      this.$emit('hidden')
    }
  },
  created () {
    this.checkedRows = this.prevCheckedRows
    this.getLocalTagList()
  },
  methods: {
    errorMessage (message = 'Ocurrió un error', type = 'is-danger') {
      this.$buefy.toast.open({
        message,
        type
      })
    },
    async getLocalTagList (params = {}) {
      try {
        this.isLoading = true
        const response = await ProductService.getLocalsList(params)
        this.results = response.data
      } catch (error) {
        this.errorMessage()
      }
      this.isLoading = false
    },
    setLink () {
      this.getLocalTagList()
    },
    setPage (page) {
      this.getLocalTagList({ page: page })
    },
    saveData () {
      if (this.checkedRows.length > 0) {
        this.$emit('localList', this.checkedRows)
        this.modalLocal = false
      } else {
        const message = 'Seleccione una o más opciones'
        this.errorMessage(message)
      }
    }
  }
}
</script>
