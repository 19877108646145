<template>
  <b-modal v-model="modalTutorial" :width="580">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <h3 class="modal-card-title is-size-4 has-text-weight-medium">
          Creación de un beneficio
        </h3>
        <button
          type="button"
          class="delete"
          @click="modalTutorial = false"
        />
      </header>
      <div class="modal-card-body">
        <div>
          <iframe
            style="aspect-ratio: 16 / 9; width: 100%; background-color: #f2f2f2;"
            src="https://www.youtube.com/embed/iJajW7WdtQM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </div>
      <footer class="modal-card-foot">
        <b-button
          label="Cerrar"
          type="is-dark"
          @click="modalTutorial = false"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  data () {
    return {
      modalTutorial: true,
      isLoading: false
    }
  },
  watch: {
    modalTutorial () {
      this.$emit('hidden')
    }
  }
}
</script>
