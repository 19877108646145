<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      <div class="columns is-1 is-variable has-text-centered">
        <div class="column">
          <b-button
            @click="showModalTutorial = true"
            type="is-primary"
            outlined
            :loading="isLoading"
          >
            <span>
              <b-icon icon="play-circle" custom-size="default" class="mr-1" />
              Ver Tutorial
            </span>
          </b-button>
        </div>
      </div>
      <div slot="right" class="columns is-1 is-variable has-text-centered">
        <div class="column">
          <b-button
            tag="router-link"
            to="/deals/add"
            type="is-primary"
            :loading="isLoading"
          >Agregar</b-button>
        </div>
      </div>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
        >
          <b-table-column label="Nombre" field="name" sortable v-slot="props">
            <router-link class="has-text-weight-medium has-text-primary" :to="{name: 'deals-detail', params: {id: props.row.id}}">
              {{ props.row.name }}
            </router-link>
          </b-table-column>
          <b-table-column label="Tipo de Descuento" field="type_discount" v-slot="props">
            {{ getTypeDiscount(props.row.type_discount) }}
          </b-table-column>
          <b-table-column label="SKU" field="sku" v-slot="props">
            {{ props.row.sku }}
          </b-table-column>
          <b-table-column label="Stock" field="stock" v-slot="props">
            {{ props.row.stock }}
          </b-table-column>
          <b-table-column label="Stock Cliente" field="stock client" v-slot="props">
            {{ props.row.stock_client }}
          </b-table-column>
          <b-table-column label="Monto Total" field="mount total" v-slot="props">
            S/. {{ parseFloat(props.row.mount_total).toFixed(2) }}
          </b-table-column>
          <b-table-column label="Estado" field="status" v-slot="props">
            <b-tag :type="getStatusClass(props.row.status)">
              {{ getStatus(props.row.status) }}
            </b-tag>
          </b-table-column>
          <loading-table slot="empty" :isLoading="isLoading" />
        </b-table>
        <Pages
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Pages>
      </card-component>
    </section>
    <ModalTutorial v-if="showModalTutorial" @hidden="showModalTutorial = false" />
  </Layout>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Layout from '@/components/Layout'
import ProductService from '@/services/ProductService'
import Pages from '@/components/Pages'
import CardComponent from '@/components/CardComponent.vue'
import LoadingTable from '@/components/Base/LoadingTable'
import ModalTutorial from '@/components/Product/ModalTutorial'
export default {
  name: 'ProductList',
  components: {
    TitleBar,
    Layout,
    Pages,
    CardComponent,
    LoadingTable,
    HeroBar,
    ModalTutorial
  },
  data () {
    return {
      isLoading: false,
      results: [],
      links: {},
      pages: {},
      showModalTutorial: false
    }
  },
  computed: {
    titleStack () {
      return ['Inicio', 'Catálogo']
    }
  },
  created () {
    this.getProductVariantPublishList()
  },
  methods: {
    errorMessage (message) {
      this.$buefy.toast.open({
        message: message ?? 'Ocurrió un error',
        type: 'is-danger'
      })
    },
    getTypeDiscount (type) {
      switch (type) {
        case 1: return 'Precio'
        case 2: return 'Porcentaje'
        case 3: return 'Regalo'
        case 4: return 'Descarga'
        case 5: return 'Monto Total'
        default: return ''
      }
    },
    getStatus (status) {
      switch (status) {
        case 'TO_REVISION': return 'En Revisión'
        case 'APPROVED': return 'Aprobado'
        case 'DECLINED': return 'Denegado'
        case 'DRAFT': return 'Borrador'
        default: return ''
      }
    },
    getStatusClass (status) {
      switch (status) {
        case 'TO_REVISION': return 'is-warning'
        case 'APPROVED': return 'is-primary'
        case 'DECLINED': return 'is-danger'
        case 'DRAFT': return 'is-info'
        default: return ''
      }
    },
    async getProductVariantPublishList (params = {}) {
      try {
        this.isLoading = true
        const response = await ProductService.getProductVariantPublishList(params)
        this.results = response.data.results
        this.pages = response.data.pages
        this.links = response.data.links
      } catch (error) {
        this.errorMessage()
      }
      this.isLoading = false
    },
    setLink () {
      this.getDeliveryList({})
    },
    setPage (page) {
      this.getDeliveryList({ ...this.query, page: page })
    }
  }
}
</script>
