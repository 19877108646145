import axios from 'axios'
class ProductService {
  getProductVariantPublishList (params = {}) {
    const response = axios.get('product-variant-publish/list', {
      params
    })
    return response
  }

  getProductTagList (params = {}) {
    const response = axios.get('product-tag/list?page_size=7', {
      params
    })
    return response
  }

  getLocalsList (params = {}) {
    const response = axios.get('locals/?page_size=7', {
      params
    })
    return response
  }

  getCategoryList (params = {}) {
    const response = axios.get('company-service/category/list?page_size=7', {
      params
    })
    return response
  }

  getProgramList (params = {}) {
    const response = axios.get('company-service/company-provider/list', {
      params
    })
    return response
  }

  postProductVariantPublish (data = {}) {
    const response = axios.post('product-variant-publish/create', data)
    return response
  }

  getProductVariantPublishDetail (id) {
    const response = axios.get(`product-variant-publish/${id}/retrieve`)
    return response
  }

  putProductVariantPublishDetail (id, data = {}) {
    const response = axios.put(`product-variant-publish/${id}/retrieve`, data)
    return response
  }

  deleteProductVariantPublishDetail (id) {
    const response = axios.delete(`product-variant-publish/${id}/destroy`)
    return response
  }

  getProductVariantList () {
    const response = axios.get('product-variant-select/list')
    return response
  }

  getProductVariantDetail (id) {
    const response = axios.get(`product-variant/${id}/detail`)
    return response
  }
}

const instance = new ProductService()
export default instance
