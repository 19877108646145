<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      <div slot="right" class="columns is-1 is-variable has-text-centered">
        <div class="column">
          <b-button type="is-primary" :loading="isLoading" @click="sendData('DRAFT')">
            Guardar Borrador
          </b-button>
        </div>
        <div class="column">
          <b-button type="is-primary" outlined :loading="isLoading" @click="sendData('TO_REVISION')">
            Enviar a Revisión
          </b-button>
        </div>
        <div class="column">
          <router-link to="/deals" class="button is-dark">
            Regresar
          </router-link>
        </div>
      </div>
    </hero-bar>
    <b-tabs
      class="section is-main-section"
      v-model="activeTab"
      position="is-centered"
      type="is-boxed"
      :animated="false"
      expanded
    >
      <b-tab-item label="Información" class="tab-custom p-5">
        <b-field>
          <template #label>
            Producto (Opcional)
            <b-tooltip multilined type="is-dark" label="Al seleccionar una opción se autocompletará todos los campos">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-select v-model="currentProduct" expanded @input="getProductVariantDetail()">
            <option :value="null">Crear un nuevo Producto</option>
            <optgroup label="O edita una promoción existente">
              <option
                v-for="product in productList"
                :value="product.id"
                :key="product.id"
              >
                {{ product.name }}
              </option>
            </optgroup>
          </b-select>
        </b-field>
        <b-field
          :type="{ 'is-danger': $v.data.program.$error }"
          :message="[
            {
              'Requerido. Selecciona un programa':
                !$v.data.program.required && $v.data.program.$error
            }
          ]"
        >
          <template #label>
            Programa de Beneficio
            <b-tooltip multilined type="is-dark" label=" Programa donde estará disponible el beneficio. Para contenido propio seleccione Beneficios Weeare">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-select v-model="data.program" expanded>
            <option :value="null">Selecione una opción</option>
            <option
              v-for="program in programList"
              :value="program.id"
              :key="program.id"
            >
              {{ program.name }}
            </option>
          </b-select>
        </b-field>
        <b-field
          :type="{ 'is-danger': $v.data.name.$error }"
          :message="[
            {
              'Requerido. Ingresa un nombre':
                !$v.data.name.required && $v.data.name.$error
            }
          ]"
        >
          <template #label>
            Nombre del Beneficio
            <b-tooltip multilined type="is-dark" label="Por ej: 50% de dscto en toda la carta">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input v-model="data.name" />
        </b-field>
        <b-field
          :type="{ 'is-danger': $v.data.sku.$error }"
          :message="[
            {
              'Requerido. Ingresa un SKU':
                !$v.data.sku.required && $v.data.name.$error
            }
          ]"
        >
          <template #label>
            SKU (Stock Keeping Unit)
            <b-tooltip multilined type="is-dark" label="Si el producto no cuenta con uno ingresa un número">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input v-model="data.sku" />
        </b-field>
        <b-field
          :type="{ 'is-danger': $v.data.stock.$error }"
          :message="[
            {
              'Requerido. Ingresa el stock':
                !$v.data.stock.required && $v.data.stock.$error
            }
          ]"
        >
          <template #label>
            Stock
            <b-tooltip multilined type="is-dark" label="Ingresa la cantidad de veces que se podrá utilizar este beneficio">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input type="number" min="0" v-model="data.stock" />
        </b-field>
        <b-field
          :type="{ 'is-danger': $v.data.stock_client.$error }"
          :message="[
            {
              'Requerido. Ingresa el stock por cliente':
                !$v.data.stock_client.required && $v.data.stock_client.$error
            }
          ]"
        >
          <template #label>
            Stock por Cliente
            <b-tooltip multilined type="is-dark" label="Ingresa cuantas veces podrá utilizar este beneficio un cliente">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input  type="number" min="0" v-model="data.stock_client" />
        </b-field>
        <b-field label="Tipo de Servicio">
          <b-select v-model="data.type_service" expanded>
            <option value="F">Libre</option>
            <option value="D">Delivery</option>
          </b-select>
        </b-field>
        <b-field>
          <template #label>
            Etiquetas (Tags)
            <b-tooltip multilined type="is-dark" label="Selecciona las etiquetas relacionadas a tu negocio">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <div
            class="is-clickable p-2 is-flex is-align-items-center is-justify-content-space-between select-custom"
            @click="showModalTag = true"
          >
            <span>
              {{
                tagList.length > 0 ? tagList.map(item => item.name).join(' | ') : 'Selecciona una o varias opciones'
              }}
            </span>
            <b-tag type="is-primary" class="level-right">
              {{ tagList.length > 0 ? 'Añadir' : 'Elegir' }}
          </b-tag>
          </div>
        </b-field>
        <b-field>
          <template #label>
            Locales
            <b-tooltip multilined type="is-dark" label="Selecciona los locales donde estará disponible este beneficio">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <div
            class="is-clickable p-2 is-flex is-align-items-center is-justify-content-space-between select-custom"
            @click="showModalLocal = true"
          >
            <span>
              {{
                localList.length > 0 ? localList.map(item => item.name).join(' | ') : 'Selecciona una o varias opciones'
              }}
            </span>
            <b-tag type="is-primary">
              {{ localList.length > 0 ? 'Añadir' : 'Elegir' }}
          </b-tag>
          </div>
        </b-field>
        <b-field>
          <template #label>
            Categorias
            <b-tooltip multilined type="is-dark" label="Selecciona la categoría relacionada a tu negocio">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <div
            class="is-clickable p-2 is-flex is-align-items-center is-justify-content-space-between select-custom"
            @click="showModalCategory = true"
          >
            <span>
              {{
                categoryList.length > 0 ? categoryList.map(item => item.name).join(' | ') : 'Selecciona una o varias opciones'
              }}
            </span>
            <b-tag type="is-primary">
              {{ categoryList.length > 0 ? 'Añadir' : 'Elegir' }}
          </b-tag>
          </div>
        </b-field>
        <b-field
          :class="{ 'is-danger': $v.data.description.$error }"
          :type="{ 'is-danger': $v.data.name.$error }"
          :message="[
            {
              'Requerido. Ingresa una descripción':
                !$v.data.description.required && $v.data.description.$error
            }
          ]"
        >
          <template #label>
            Descripción
            <b-tooltip multilined type="is-dark" label="Describe con detalle lo que incluye el beneficio. Por ej: 50% de descuento en platos especiales">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <vue-editor v-model="data.description" :editorToolbar="customToolbar">
          </vue-editor>
        </b-field>
      </b-tab-item>
      <b-tab-item label="Detalles" class="tab-custom p-5">
        <b-field
          label="Tipo de beneficio"
          :type="{ 'is-danger': $v.data.type_discount.$error }"
          :message="[
            {
              'Requerido. Elige una opción':
                !$v.data.type_discount.required && $v.data.type_discount.$error
            }
          ]"
        >
          <b-select v-model="data.type_discount" expanded @input="validateInputs">
            <option value="1">Monto Fijo</option>
            <option value="2">Porcentaje</option>
            <option value="5">Monto Total</option>
          </b-select>
        </b-field>
        <b-field
          v-if="data.type_discount == 5"
          :type="{ 'is-danger': $v.data.mount_total.$error }"
          :message="[
            {
              'Requerido. Ingresa el monto total':
                !$v.data.mount_total.required && $v.data.mount_total.$error
            }
          ]"
        >
          <template #label>
            Valor Percibido
            <b-tooltip multilined type="is-dark" label="Es el precio del regalo a otorgar">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input  type="number" step="0.1" min="0" v-model="data.mount_total" />
        </b-field>
        <b-field>
          <template #label>
            Descuento {{ data.type_discount == 1 ? '(S/.)' : '(%)' }}
            <b-tooltip multilined type="is-dark" label="Ingresa el monto fijo de descuento">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input :disabled="data.type_discount == 5" type="number" step="0.1" min="0" v-model="data.discount" />
        </b-field>
        <b-field>
          <template #label>
            Monto Mínimo de Compra
            <b-tooltip multilined type="is-dark" label="Ingresa el monto mínimo de compra para que se realice el descuento">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input :disabled="data.type_discount == 5"  type="number" step="0.1" min="0" v-model="data.min_discount" />
        </b-field>
        <b-field>
          <template #label>
            Máximo de Descuento en S/.
            <b-tooltip multilined type="is-dark" label="Ingresa el monto máximo que deberá descontarse">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-input :disabled="data.type_discount == 5"  type="number" step="0.1" min="0" v-model="data.price_percentage" />
        </b-field>
        <b-field label="Activo Desde">
          <b-datepicker
            placeholder="Elige una fecha"
            v-model="data.active_date_from"
            :min-date="minDate"
            :append-to-body="true"
            :mobile-native="false"
          >
            <b-button class="button is-danger" @click="data.active_date_from = null">
              <b-icon icon="close-circle-outline" custom-size="default"/>
              <span>Limpiar</span>
            </b-button>
          </b-datepicker>
        </b-field>
        <b-field label="Activo Hasta">
          <b-datepicker
            placeholder="Elige una fecha"
            v-model="data.active_date_to"
            :min-date="data.active_date_from || minDate"
            :append-to-body="true"
            :mobile-native="false"
          >
            <b-button class="button is-danger" @click="data.active_date_to = null">
              <b-icon icon="close-circle-outline" custom-size="default"/>
              <span>Limpiar</span>
            </b-button>
          </b-datepicker>
        </b-field>
        <b-field>
          <template #label>
            Imagen
            <b-tooltip multilined type="is-dark" label="Imagen en PNG o JPG. Medida recomendada 1200 x 800 px">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <upload
            :image="data.image"
            @change="uploadFileImage"
          ></upload>
        </b-field>
        <b-field>
          <template #label>
            Terminos y Condiciones
            <b-tooltip multilined type="is-dark" label="Ingresa todas las condiciones y restricciones de uso de un beneficio">
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <vue-editor v-model="data.terms_conditions" :editorToolbar="customToolbar">
          </vue-editor>
        </b-field>
      </b-tab-item>
    </b-tabs>
    <ModalLocal
      v-if="showModalLocal"
      @hidden="showModalLocal = false"
      @localList="loadLocalList"
      :prevCheckedRows="localList"
    />
    <ModalTag
      v-if="showModalTag"
      @hidden="showModalTag = false"
      @tagList="loadTagList"
      :prevCheckedRows="tagList"
    />
    <ModalCategory
      v-if="showModalCategory"
      @hidden="showModalCategory = false"
      @categoryList="loadCategoryList"
      :prevCheckedRows="categoryList"
    />
  </Layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'
import formatDate from '@/mixins/formatDate'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Layout from '@/components/Layout'
import ProductService from '@/services/ProductService'
import Upload from '@/components/Base/Upload'
import ModalLocal from '@/components/Product/ModalLocal'
import ModalTag from '@/components/Product/ModalTag'
import ModalCategory from '@/components/Product/ModalCategory'
export default {
  name: 'ProductAdd',
  components: {
    HeroBar,
    TitleBar,
    Layout,
    VueEditor,
    Upload,
    ModalLocal,
    ModalTag,
    ModalCategory
  },
  mixins: [formatDate],
  data () {
    const today = new Date()
    return {
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'] // remove formatting button
      ],
      activeTab: 0,
      isLoading: false,
      data: {
        name: '',
        stock: null,
        stock_client: null,
        description: '',
        sku: '',
        mount_total: 0,
        image: null,
        tags: [],
        locals: [],
        categories: [],
        programs: null,
        type_discount: 1,
        active_date_from: null,
        active_date_to: null,
        terms_conditions: '',
        type_service: 'F',
        min_discount: 0,
        discount: 0,
        price_percentage: 0,
        program: null
      },
      router_image: null,
      formData: new FormData(),
      showModalTag: false,
      showModalLocal: false,
      showModalCategory: false,
      showModalProgram: false,
      tagList: [],
      localList: [],
      categoryList: [],
      productList: [],
      programList: [],
      currentProduct: null
    }
  },
  validations: {
    data: {
      name: { required },
      stock: { required },
      stock_client: { required },
      description: { required },
      sku: { required },
      mount_total: { required },
      program: { required },
      type_discount: { required }
    }
  },
  created () {
    this.getProductVariant()
    this.getProgramList()
  },
  computed: {
    titleStack () {
      return ['Inicio', 'Catálogo', 'Agregar']
    }
  },
  methods: {
    validateInputs () {
      this.data.mount_total = 0
      this.data.discount = 0
      this.data.min_discount = 0
      this.data.price_percentage = 0
      if (this.data.type_discount === '5') {
        this.data.discount = 100
      }
    },
    infoMessage (message = 'Ocurrió un error.', type = 'is-danger') {
      this.$buefy.toast.open({
        message,
        type
      })
    },
    async getProductVariant () {
      try {
        const response = await ProductService.getProductVariantList()
        this.productList = response.data
      } catch (error) {
        this.infoMessage()
      }
    },
    async getProgramList () {
      try {
        const response = await ProductService.getProgramList()
        this.programList = response.data
      } catch (error) {
        this.infoMessage()
      }
    },
    async convertURLtoFile (url) {
      try {
        const response = await fetch(url)
        const data = await response.blob()
        const filename = url.split('/').pop()
        const metadata = { type: 'image/*' }
        const newFile = new File([data], filename, metadata)
        this.uploadFileImage(newFile)
      } catch (error) {
        this.infoMessage()
      }
    },
    async getProductVariantDetail () {
      try {
        const response = this.currentProduct ? await ProductService.getProductVariantDetail(this.currentProduct) : []
        this.data.name = response?.data?.name || ''
        this.data.stock = response?.data?.stock || ''
        this.data.stock_client = response?.data?.stock_client || ''
        this.data.description = response?.data?.description || ''
        this.data.sku = response?.data?.sku || ''
        this.data.mount_total = parseFloat(response?.data?.mount_total).toFixed(2) || ''
        if (response?.data?.image_url) {
          this.convertURLtoFile(response?.data?.image_url)
          this.data.image = response?.data?.image_url || null
        }
        this.data.terms_conditions = response?.data?.terms_conditions || ''
        this.data.type_discount = response?.data?.type_discount || null
        this.data.type_service = response?.data?.type_service || ''
        this.data.min_discount = response?.data?.min_discount || ''
        this.data.discount = response?.data?.discount || ''
        this.data.price_percentage = response?.data?.price_percentage || ''
        this.loadTagList(response?.data?.tags || [])
        this.loadLocalList(response?.data?.locals || [])
        this.loadCategoryList(response?.data?.categories || [])
        this.data.active_date_to = response?.data?.active_date_to ? new Date(this.formatDateInverted(response?.data?.active_date_to)) : null
        this.data.active_date_from = response?.data?.active_date_from ? new Date(this.formatDateInverted(response?.data?.active_date_from)) : null
      } catch (error) {
        this.infoMessage()
      }
    },
    async sendData (status) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          this.formData.append('name', this.data.name)
          this.formData.append('stock', this.data.stock)
          this.formData.append('stock_client', this.data.stock_client)
          this.formData.append('description', this.data.description)
          this.formData.append('sku', this.data.sku)
          this.formData.append('mount_total', this.data.mount_total)
          this.formData.append('status', status)
          if (this.currentProduct) this.formData.append('product_variant', this.currentProduct)
          for (let i = 0; i < this.data.tags.length; i++) {
            this.formData.append('tags', this.data.tags[i])
          }
          for (let i = 0; i < this.data.locals.length; i++) {
            this.formData.append('locals', this.data.locals[i])
          }
          for (let i = 0; i < this.data.categories.length; i++) {
            this.formData.append('categories', this.data.categories[i])
          }
          this.formData.append('type_discount', this.data.type_discount)
          if (this.data.active_date_from) this.formData.append('active_date_from', this.formatDateReverse(this.data.active_date_from))
          if (this.data.active_date_to) this.formData.append('active_date_to', this.formatDateReverse(this.data.active_date_to))
          this.formData.append('terms_conditions', this.data.terms_conditions)
          this.formData.append('type_service', this.data.type_service)
          if (this.data.min_discount) this.formData.append('min_discount', this.data.min_discount)
          if (this.data.discount) this.formData.append('discount', this.data.discount)
          if (this.data.price_percentage) this.formData.append('price_percentage', this.data.price_percentage)
          this.formData.append('program', this.data.program)
          await ProductService.postProductVariantPublish(this.formData)
          this.$router.push('/deals')
          this.infoMessage('Se guardó correctamente.', 'is-success')
        } catch (error) {
          this.infoMessage()
        }
        this.isLoading = false
      } else {
        this.infoMessage('Complete los campos para continuar.')
      }
    },
    uploadFileImage (file) {
      if (file) {
        this.formData.append('image', file)
        this.route_image = file
      }
    },
    loadTagList (data) {
      this.tagList = data
      this.data.tags = this.tagList.map(item => item.id)
    },
    loadLocalList (data) {
      this.localList = data
      this.data.locals = this.localList.map(item => item.id)
    },
    loadCategoryList (data) {
      this.categoryList = data
      this.data.categories = this.categoryList.map(item => item.id)
    }
  }
}
</script>
